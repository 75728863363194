/**
 * External Dependencies
 */
import React, { Component } from 'react';
import classnames from 'classnames/dedupe';
import { connect } from 'react-redux';
// import Content from '../pages/Typography';
import { Link } from 'react-router-dom';
import { Collapse,Button } from 'reactstrap';
import Cookies from 'js-cookie';
import Select from 'react-select';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import 'react-google-flight-datepicker/dist/main.css';
// import DatePicker from "react-datepicker";
import DatePicker from '../../components/date-time-picker';
import dateFormat from 'dateformat';
import { format } from 'date-fns';
// var user_page=(Cookies.get('user_management_Expense_managemnt'))
// var interest_page=(Cookies.get('interest_management_Expense_managemnt',"interest"))
// var category_page=(Cookies.get('category_management_Expense_managemnt',"category"))
// var bank_page=(Cookies.get('bank_management_Expense_managemnt',"bank"))


var home_general_page=(Cookies.get('ledger_management_Expense_managemnt',"home_general"))
var company_ledger_page=(Cookies.get('ledger_management_Expense_managemnt',"company_ledger"))
var master_pagesss = (Cookies.get('ledger_management_Expense_managemnt',"master_page"))

console.log("ghghghhghghghghghg", Cookies.get("Expense_managemnt_selected_project_cookie"));
var project_id = Cookies.get("Expense_managemnt_selected_project_cookie")
console.log("coooooo", project_id);

var cookieeee = (Cookies.get('Expense_managemnt'))
console.log("gggggggggggggggg",Cookies.get('Expense_managemnt'));
// console.log("Main_user_idddddddd",JSON.parse(Cookies.get('Expense_managemnt')));
// var user_dat=JSON.parse(Cookies.get('Expense_managemnt'))

if (cookieeee == undefined) {
    var user_dat = (Cookies.get('Expense_managemnt'))
}
else{
    var user_dat = JSON.parse(Cookies.get('Expense_managemnt'))
}

/**
 * Internal Dependencies
 */
import {
    updateAuth as actionUpdateAuth,
    updateSettings as actionUpdateSettings,
} from '../../actions';
import Dropdown from '../bs-dropdown';
import Icon from '../icon';
import FancyBox from '../fancybox';
import PagePopupSearch from '../page-popup-search';
import Messenger from '../messenger';
import { initNavbar } from '../../../../common-assets/js/rootui-parts/initNavbar';

const $ = window.jQuery;

window.RootUI.initNavbar = initNavbar;

// var path = "http://192.168.29.31:6009/#/sign-up"

var device_width = window.innerWidth;

/**
 * Component
 */
class PageNavbar extends Component {
    constructor( props ) {
        super( props );

        window.RootUI.initNavbar();

        this.state = {
            mobileMenuShow: false,
            nav_heading:'',
            user_id:user_dat[0].id,
            category_id:'',
            sub_category_id:'',
            type_array:[],
            category_array:[],
            sub_category_array:[],
            sub_category_name:'',
            category_name:'',
            type:'',
            // login_name : login_data[0].name
        };

        // this.logOut = this.logOut.bind( this );
        this.renderSubmenus = this.renderSubmenus.bind( this );
        this.renderRightMenuItems = this.renderRightMenuItems.bind( this );
        this.fetch_home_ledger();
        this.fetch_category();
        this.fetch_company_ledger();
    }

    componentDidMount() {
        $( document ).on( 'keydown.rui-navbar', ( e ) => {
            const {
                mobileMenuShow,
            } = this.state;

            if ( mobileMenuShow && e.keyCode === 27 ) {
                this.setState( {
                    mobileMenuShow: ! mobileMenuShow,
                } );
            }
        } );
        this.head_sec();
    }


    back_home = () => {
        console.log("back jome+++++++");
        const {
            updateAuth,
        } = this.props;

        updateAuth({
            crm_token2: '',
            // crm_token: '',
        });
        Cookies.remove("Expense_managemnt_selected_project_cookie");
        this.setState(() => {
            setTimeout(() => {
                this.setState({
                    loading: false,
                })
            });
            setTimeout(() => {
              location.hash = "/sign-up"
                window.location.reload();
            });
        });

    }

    fetch_category() {
        const params = {
          mode: 'fetch_category'
        }
          const { settings } = this.props;
        var category_data = null;
        const res = fetch(settings.api_url, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            }
        }).then((response) => response.json())
            .then(json => {
                console.log(json)
                category_data = json;
            })
            .then(() => {
              // this.fetch_single_category(category_data.data[0].id)
                this.setState({
                   category_array: category_data["data"],
                  });
                console.log("category_array_navbarrr*************************",category_data["data"]);
                console.log(" job_data.status", category_data.status);
            })
    }


    fetch_sub_category(category_id) {
      console.log("fetch_sub_category",category_id);
        const params = {
          mode: 'fetch_sub_category',
          category_id:category_id
        }
        console.log("params*****************",params);
        const { settings } = this.props;
        var fetch_sub_category = null;
        const res = fetch(settings.api_url, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            }
        }).then((response) => response.json())
            .then(json => {
                console.log(json)
                fetch_sub_category = json;
            })
            .then(() => {
               if (fetch_sub_category.status == true){
                this.setState({
                 sub_category_array: fetch_sub_category["data"]
              });
                console.log("  fetch_sub_category_navbarrr*************************************", fetch_sub_category["data"]);
              }
              else{
                this.setState({
                  sub_category_array: []
               })
            }
              }
            )
    }

    fetch_home_ledger(category_id,sub_category_id,type,date_range) {
      const { settings } = this.props;
      if (category_id== undefined) {
        var sel_cat = ""
      }
      else{
        var sel_cat = category_id.value
      }

      if (sub_category_id== undefined) {
        var sel_sub_cat = ""
      }
      else{
        var sel_sub_cat = sub_category_id.value
      }

      if (type == undefined) {
       var  sel_type = ""
      }
      else{
       var  sel_type = type.label
      }
      console.log(date_range,"date_range");
      console.log(category_id,"category_id");
      console.log(sub_category_id,"sub_category_id");
      console.log(type,"type");

      var params = {
         mode : "fetch_home_ledger",
          user_id:this.state.user_id,
          date_range:date_range,
          category_id:sel_cat,
          sub_category_id: sel_sub_cat,
          type:sel_type,
      }

      console.log("params_navbar*****************************",params);
            var user_data = null;
                const res = fetch(settings.api_url, {
                    method: 'POST',
                     body: JSON.stringify(params),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    }
                })
                .then((response) => response.json())
                    .then(json => {
                        console.log("user data fetched successfully", json)
                        user_data = json;
                        if (user_data.status == true) {

                          var data_array = user_data.data;
                          // for (let i = 0; i < data_array.length; i++) {
                          //
                          //   console.log("gggggggggggg",i);
                          //   if(i==0){
                          //     console.log("in if codition______");
                          //     if(data_array[0].type== "credit"){
                          //       var balance= parseInt(data_array[0].amount)
                          //     }
                          //     else{
                          //         var balance= parseInt(-data_array[0].amount)
                          //     }
                          //     data_array[0].show_balance = balance
                          //   }else{
                          //     console.log("in else condition______");
                          //     if(data_array[i].type=="credit"){
                          //       var balance= data_array[i-1].show_balance + parseInt(data_array[i].amount)
                          //     }else{
                          //       var balance= data_array[i-1].show_balance - parseInt(data_array[i].amount)
                          //     }
                          //     data_array[i].show_balance = balance
                          //   }
                          //
                          // }


                          for (let i = data_array.length-1; i >= 0; i--) {
                            console.log("iiiiiiii*************========",i);
                            if(i == data_array.length-1){
                              console.log("in if codition______");
                              if(data_array[data_array.length-1].type== "credit"){
                                var balance= parseInt(data_array[data_array.length-1].amount)
                              }
                              else{
                                  var balance= parseInt(-data_array[data_array.length-1].amount)
                              }
                              data_array[data_array.length-1].show_balance = balance
                            }else{
                              console.log("in else condition______");
                              if(data_array[i].type=="credit"){
                                var balance= data_array[i+1].show_balance + parseInt(data_array[i].amount)
                              }else{
                                var balance= data_array[i+1].show_balance - parseInt(data_array[i].amount)
                              }
                              data_array[i].show_balance = balance
                            }
                          }

                          console.log("data_array======== ############",data_array);

                          this.setState({
                              user_array: data_array, isLoading:"none", no_data:"none",
                              date_range:'',
                          })
                    console.log("user_array", this.state.user_array);
                    console.log(" user_data status", user_data.status);
                  } else {
                      this.setState({ user_array: [], isLoading: "none" ,no_data:"block" });
                  }
                });
      }

      fetch_company_ledger(category_id,sub_category_id,type,date_range) {
        const { settings } = this.props;
        if (category_id== undefined) {
          var sel_cat = ""
        }
        else{
          var sel_cat = category_id.value
        }

        if (sub_category_id== undefined) {
          var sel_sub_cat = ""
        }
        else{
          var sel_sub_cat = sub_category_id.value
        }

        if (type == undefined) {
         var  sel_type = ""
        }
        else{
         var  sel_type = type.label
        }
        console.log(date_range,"date_range");
        console.log(category_id,"category_id");
        console.log(sub_category_id,"sub_category_id");
        console.log(type,"type");

        var params = {
           mode : "fetch_company_ledger",
            user_id: this.state.user_id,
            date_range:date_range,
            category_id:sel_cat,
            sub_category_id: sel_sub_cat,
            type:sel_type,
        }
        console.log("fetch_company_ledger*****************************",params);
              var user_data = null;
                  const res = fetch(settings.api_url, {
                      method: 'POST',
                       body: JSON.stringify(params),
                      headers: {
                          "Content-type": "application/json; charset=UTF-8",
                      }
                  })
                  .then((response) => response.json())
                      .then(json => {
                          console.log("user data fetched successfully", json)
                          user_data = json;
                          if (user_data.status == true) {

                            var data_array = user_data.data;
                            // for (let i = 0; i < data_array.length; i++) {
                            //
                            //   console.log("gggggggggggg",i);
                            //   if(i==0){
                            //     console.log("in if codition______");
                            //     if(data_array[0].type== "credit"){
                            //       var balance= parseInt(data_array[0].amount)
                            //     }
                            //     else{
                            //         var balance= parseInt(-data_array[0].amount)
                            //     }
                            //     data_array[0].show_balance = balance
                            //   }else{
                            //     console.log("in else condition______");
                            //     if(data_array[i].type=="credit"){
                            //       var balance= data_array[i-1].show_balance + parseInt(data_array[i].amount)
                            //     }else{
                            //       var balance= data_array[i-1].show_balance - parseInt(data_array[i].amount)
                            //     }
                            //     data_array[i].show_balance = balance
                            //   }
                            //
                            // }


                            for (let i = data_array.length-1; i >= 0; i--) {

                              console.log("iiiiiiii*************========",i);
                              if(i==data_array.length-1){
                                console.log("in if codition______");
                                if(data_array[data_array.length-1].type== "credit"){
                                  var balance= parseInt(data_array[data_array.length-1].amount)
                                }
                                else{
                                    var balance= parseInt(-data_array[data_array.length-1].amount)
                                }
                                data_array[data_array.length-1].show_balance = balance
                              }else{
                                console.log("in else condition______");
                                if(data_array[i].type=="credit"){
                                  var balance= data_array[i+1].show_balance + parseInt(data_array[i].amount)
                                }else{
                                  var balance= data_array[i+1].show_balance - parseInt(data_array[i].amount)
                                }
                                data_array[i].show_balance = balance
                              }

                            }


                            this.setState({
                                user_array: data_array, isLoading:"none", no_data:"none",
                                date_range:'',
                            })
                      console.log("user_array", this.state.user_array);
                      console.log(" user_data status", user_data.status);
                    } else {
                        this.setState({ user_array: [], isLoading: "none" ,no_data:"block" });
                    }


                  });
        }



    componentWillUnmount() {
        $( document ).off( 'keydown.rui-navbar' );
    }

    // logOut() {
    //     const {
    //         updateAuth,
    //     } = this.props;

    //     updateAuth( {
    //         token2: '',
    //     } );
    // }

    head_sec (){
      console.log("sameeeeeeeeeeeeeeeeeee",Cookies.get('ledger_management_Expense_managemnt'));
      var my_cooki =Cookies.get('ledger_management_Expense_managemnt')
      console.log("my_cooookiesssss",my_cooki);
      if (my_cooki=="Dashboard_home"){
      var new_data="Home Dashboard"
      console.log(new_data,"Home Dashboard");
      console.log(this.state.nav_heading,"nav_heading_for_home data");
        // this.state.nav_heading = "Home Ledger Management"
      }
       else if (my_cooki=="home_general"){
    //   var new_data="Home Ledger Management"
    //   console.log(new_data,"navvvvvvvvvvvvvvvvvvv Home Ledger Management");
    //   console.log(this.state.nav_heading,"nav_heading_for_homeeeeeeeeeeee data");
        // this.state.nav_heading = "Home Ledger Management"
      }
       else if (my_cooki=="Dashboard_company"){
      var new_data="Company Dashboard"
      console.log(new_data,"navvvvvvvvvvvvvvvvvvv Company Dashboard");
      console.log(this.state.nav_heading,"nav_heading_for_company data");
        // this.state.nav_heading = "Home Ledger Management"
      }
    //    else if (my_cooki=="company_ledger"){
    //   var new_data="Company Ledger Management"
    //   console.log(new_data,"navvvvvvvvvvvvvvvvvvv Company Ledger Management");
    //   console.log(this.state.nav_heading,"nav_heading_for_home data");
    //   }
       else if (my_cooki=="master_page"){
      var new_data=""
      console.log(new_data,"navvvvvvvvvvvvvvvvvvv master_page Management");
      console.log(this.state.nav_heading,"nav_heading_for_home data");
        // this.state.nav_heading = "Home Ledger Management"
      }
      else{
        var new_data=""
        console.log(this.state.nav_heading,"nav_heading_for_home data");
        console.log(new_data,"New manegament");
      }

      this.setState({
        nav_heading:new_data
      })
      console.log(new_data,"dattttttttaaaaaaaaaaaaaaa++++++++++++");

    }

    renderSubmenus( nav, isMobile, level = 1 ) {
        return Object.keys( nav ).map( ( url ) => {
            const data = nav[ url ];

            const isActive = window.location.hash === `#${ url }`;

            const LinkContent = data.name ? (
                <>
                    { data.icon ? (
                        <>
                            <Icon name={ data.icon } />
                            <span>{ data.name }</span>
                            <span className={ data.sub ? 'rui-dropdown-circle' : 'rui-nav-circle' } />
                        </>
                    ) : (
                        data.name
                    ) }
                </>
            ) : '';

            return (
                <>

                {/*<React.Fragment key={ `${ url }-${ data.name }` }>

                    { data.sub ? (
                        <Dropdown tag="li" className={ classnames( isActive ? 'active' : '' ) } direction={ level === 1 ? 'up' : 'right' } openOnHover={ ! isMobile } showTriangle>
                            <Dropdown.Toggle tag="a" href="#" className="dropdown-item">
                                { LinkContent }
                            </Dropdown.Toggle>
                            <Dropdown.Menu tag="ul" className="nav dropdown-menu">
                                { this.renderSubmenus( data.sub, isMobile, level + 1 ) }
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (

                        <li className={ classnames( 'nav-item', isActive ? 'active' : '' ) }>
                            <Link
                                to={ data.sub ? '#' : url }
                                className="nav-link"
                            >
                                { LinkContent }
                            </Link>
                        </li>
                    ) }
                </React.Fragment>*/}
                </>
            );
        } );
    }


    renderRightMenuItems(isMobile) {
        const {
            settings,
            updateSettings,
        } = this.props;

        const countriesDropdown = [
            {
                name: 'USA',
                img: settings.img_country.usa,
            },
            {
                name: 'China',
                img: settings.img_country.china,
            },
            {
                name: 'Germany',
                img: settings.img_country.germany,
            },
            {
                name: 'Japan',
                img: settings.img_country.japan,
            },
            {
                name: 'Spain',
                img: settings.img_country.spain,
            },
        ];

        return (
            <>
                {/* <li className="nav-item">
                    <FancyBox
                        tagName="a"
                        className={
                            classnames(
                                'd-flex',
                                isMobile ? 'nav-link' : ''
                            )
                        }
                        touch={ false }
                        closeExisting
                        autoFocus
                        parentEl={ null }
                        popupClassName="rui-popup rui-popup-search container"
                        popupRender={ () => (
                            <PagePopupSearch settings={ settings } />
                        ) }
                    >
                        { isMobile ? (
                            <>
                                <Icon name="search" />
                                <span>Search</span>
                            </>
                        ) : (
                            <span className="btn btn-custom-round">
                                <Icon name="search" />
                            </span>
                        ) }
                    </FancyBox>
                </li> */}
                {/* <Dropdown tag="li" direction="up" openOnHover={ ! isMobile } showTriangle>
                    <Dropdown.Toggle
                        tag="a"
                        href="#"
                        className={
                            classnames(
                                'dropdown-item',
                                isMobile ? 'nav-link' : ''
                            )
                        }
                    >
                        { isMobile ? (
                            <>
                                <Icon name="flag" />
                                <span>Language</span>
                            </>
                        ) : (
                            <span className="btn btn-custom-round">
                                <Icon name="flag" />
                            </span>
                        ) }
                    </Dropdown.Toggle>
                    <Dropdown.Menu tag="ul" className="nav dropdown-menu rui-navbar-dropdown-language" modifiers={ { offset: { offset: '0,12' } } }>
                        { countriesDropdown.map( ( data ) => (
                            <li key={ data.name }>
                                <Link to="#" className="rui-navbar-language">
                                    <span className="rui-navbar-language-img">
                                        <img src={ data.img } alt={ data.name } />
                                    </span>
                                    { data.name }
                                </Link>
                            </li>
                        ) ) }
                    </Dropdown.Menu>
                </Dropdown> */}
                {/* <Dropdown tag="li" direction="up" openOnHover={ ! isMobile } showTriangle>
                    <Dropdown.Toggle
                        tag="a"
                        href="#"
                        className={
                            classnames(
                                'dropdown-item',
                                isMobile ? 'nav-link' : ''
                            )
                        }
                    >
                        { isMobile ? (
                            <>
                                <Icon name="bell" />
                                <span>Notifications</span>
                                <span className="badge badge-circle badge-brand">3</span>
                            </>
                        ) : (
                            <span className="btn btn-custom-round">
                                <Icon name="bell" className="mr-0" />
                                <span className="badge badge-circle badge-brand">3</span>
                            </span>
                        ) }
                    </Dropdown.Toggle>
                    <Dropdown.Menu tag="ul" className="nav dropdown-menu rui-navbar-dropdown-notice" modifiers={ { offset: { offset: '0,12' } } }>
                        <li className="rui-navbar-dropdown-title mb-10">
                            <div className="d-flex align-items-center">
                                <h2 className="h4 mb-0 mr-auto">Notifications</h2>
                                <Link to="/profile" className="btn btn-custom-round">
                                    <Icon name="link2" />
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="media media-success media-filled mnl-30 mnr-30">
                                <Link to="/profile" className="media-link">
                                    <span className="media-img"><img src={ settings.users[ 4 ].img } alt="" /></span>
                                    <span className="media-body">
                                        <span className="media-title">{ settings.users[ 4 ].name }</span>
                                        <small className="media-subtitle">Bring abundantly creature great...</small>
                                    </span>
                                </Link>
                                <Link to="#" className="media-icon">
                                    <Icon name="x" />
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="media media-filled mnl-30 mnr-30">
                                <Link to="/profile" className="media-link">
                                    <span className="media-img">C</span>
                                    <span className="media-body">
                                        <span className="media-title">Change Design</span>
                                        <small className="media-subtitle">Design</small>
                                    </span>
                                </Link>
                                <Link to="#" className="media-icon">
                                    <Icon name="x" />
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="media media-filled mnl-30 mnr-30">
                                <Link to="/profile" className="media-link">
                                    <span className="media-img bg-transparent"><img src={ settings.img_file.zip } className="icon-file" alt="" /></span>
                                    <span className="media-body">
                                        <span className="media-title">Added banner archive</span>
                                        <small className="media-subtitle">Commerce</small>
                                    </span>
                                </Link>
                                <Link to="#" className="media-icon">
                                    <Icon name="x" />
                                </Link>
                            </div>
                        </li>
                    </Dropdown.Menu>
                </Dropdown> */}
                {/* <li className="nav-item">
                    <FancyBox
                        tagName="a"
                        className={
                            classnames(
                                'd-flex',
                                isMobile ? 'nav-link' : ''
                            )
                        }
                        keyboard={ false }
                        touch={ false }
                        closeExisting
                        autoFocus
                        popupClassName="rui-popup rui-popup-messenger"
                        popupRender={ () => (
                            <Messenger settings={ settings } />
                        ) }
                    >
                        { isMobile ? (
                            <>
                                <Icon name="message-circle" />
                                <span>Messenger</span>
                            </>
                        ) : (
                            <span className="btn btn-custom-round">
                                <Icon name="message-circle" />
                            </span>
                        ) }
                    </FancyBox>
                </li> */}
                {/* { ! isMobile ? (
                    <Dropdown tag="li" direction="up" openOnHover showTriangle>
                        <Dropdown.Toggle tag="a" href="#" className="dropdown-item rui-navbar-avatar mnr-6">
                            <img src={ settings.users[ 0 ].img } alt="" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu tag="ul" className="nav dropdown-menu">
                            <li>
                                <Link to="/profile" className="nav-link">
                                    <Icon name="plus-circle" />
                                    <span>Create new Post</span>
                                    <span className="rui-nav-circle" />
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile" className="nav-link">
                                    <Icon name="users" />
                                    <span>Manage Users</span>
                                    <span className="rui-nav-circle" />
                                </Link>
                            </li>
                            <li>
                                <Link to="/profile" className="nav-link">
                                    <Icon name="check-circle" />
                                    <span>Check Updates</span>
                                    <span className="rui-nav-circle" />
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="nav-link"
                                    onClick={ this.logOut }
                                >
                                    <Icon name="log-out" />
                                    <span>Log Out</span>
                                    <span className="rui-nav-circle" />
                                </Link>
                            </li>
                        </Dropdown.Menu>
                    </Dropdown>
                ) : '' } */}

               <Button className="text-light" color="warning"  onClick={this.back_home}>Home</Button>
               <Dropdown tag="li" direction="up" openOnHover={ ! isMobile } showTriangle>
                   <Dropdown.Toggle
                       tag="a"
                       className={
                           classnames(
                               'dropdown-item mnr-5',
                               isMobile ? 'nav-link' : ''
                           )
                       }
                       nav-link
                   >
                       { isMobile ? (
                           <>
                               <Icon name="more-vertical" />
                               <span>More</span>
                               <span className="rui-dropdown-circle" />
                           </>
                       ) : (
                           <span className="btn btn-custom-round">
                               <Icon name="more-vertical" />
                           </span>
                       ) }
                   </Dropdown.Toggle>
                   <Dropdown.Menu tag="ul" className="nav dropdown-menu" modifiers={ { offset: { offset: '0,12' } } }>
                       <li>
                           <div className="custom-control custom-switch dropdown-item-switch">
                               <input
                                   type="checkbox"
                                   className="custom-control-input"
                                   id="toggleNightMode"
                                   checked={ settings.night_mode }
                                   onChange={ () => {
                                       updateSettings( {
                                           night_mode: ! settings.night_mode,
                                       } )
                                       Cookies.set( 'night_mode_cookie', ! settings.night_mode );
                                       console.log("! settings.night_mode_cookie",! settings.night_mode);
                                   } }
                               />
                               { /* eslint-disable-next-line */ }
                               <label className="dropdown-item custom-control-label" htmlFor="toggleNightMode">
                                   <Icon name="moon" />
                                   <span>Night Mode</span>
                                   <span className="rui-dropdown-circle" />
                               </label>
                           </div>
                       </li>
                       <li>
                           <div className="custom-control custom-switch dropdown-item-switch">
                               <input
                                   type="checkbox"
                                   className="custom-control-input"
                                   id="toggleSpotlightMode"
                                   checked={ settings.spotlight_mode }
                                   onChange={ () => {
                                       updateSettings( {
                                           spotlight_mode: ! settings.spotlight_mode,
                                       } );
                                   } }
                               />
                               { /* eslint-disable-next-line */ }
                               <label className="dropdown-item custom-control-label" htmlFor="toggleSpotlightMode">
                                   <Icon name="square" />
                                   <span>Spotlight Mode</span>
                                   <span className="rui-dropdown-circle" />
                               </label>
                           </div>
                       </li>
                       <li>
                           <div className="custom-control custom-switch dropdown-item-switch">
                               <input
                                   type="checkbox"
                                   className="custom-control-input"
                                   id="toggleSectionLines"
                                   checked={ settings.show_section_lines }
                                   onChange={ () => {
                                       updateSettings( {
                                           show_section_lines: ! settings.show_section_lines,
                                       } );
                                   } }
                               />
                               { /* eslint-disable-next-line */ }
                               <label className="dropdown-item custom-control-label" htmlFor="toggleSectionLines">
                                   <Icon name="layout" />
                                   <span>Show section lines</span>
                                   <span className="rui-dropdown-circle" />
                               </label>
                           </div>
                       </li>
                       <li className="dropdown-menu-label">Sidebar</li>
                       <li>
                           <div className="custom-control custom-switch dropdown-item-switch">
                               <input
                                   type="checkbox"
                                   className="custom-control-input"
                                   id="toggleDarkSidebar"
                                   checked={ settings.sidebar_dark }
                                   onChange={ () => {
                                       updateSettings( {
                                           sidebar_dark: ! settings.sidebar_dark,
                                       } );
                                   } }
                               />
                               { /* eslint-disable-next-line */ }
                               <label className="dropdown-item custom-control-label" htmlFor="toggleDarkSidebar">
                                   <Icon name="sidebar" />
                                   <span>Dark</span>
                                   <span className="rui-dropdown-circle" />
                               </label>
                           </div>
                       </li>
                       <li>
                           <div className="custom-control custom-switch dropdown-item-switch">
                               <input
                                   type="checkbox"
                                   className="custom-control-input"
                                   id="toggleStaticSidebar"
                                   checked={ settings.sidebar_static }
                                   onChange={ () => {
                                       updateSettings( {
                                           sidebar_static: ! settings.sidebar_static,
                                       } );
                                   } }
                               />
                               { /* eslint-disable-next-line */ }
                               <label className="dropdown-item custom-control-label" htmlFor="toggleStaticSidebar">
                                   <Icon name="sidebar" />
                                   <span>Static</span>
                                   <span className="rui-dropdown-circle" />
                               </label>
                           </div>
                       </li>
                       <li className="dropdown-menu-label">Navbar</li>
                       <li>
                           <div className="custom-control custom-switch dropdown-item-switch">
                               <input
                                   type="checkbox"
                                   className="custom-control-input"
                                   id="toggleDarkNavbar"
                                   checked={ settings.nav_dark }
                                   onChange={ () => {
                                       updateSettings( {
                                           nav_dark: ! settings.nav_dark,
                                       } );
                                   } }
                               />
                               { /* eslint-disable-next-line */ }
                               <label className="dropdown-item custom-control-label" htmlFor="toggleDarkNavbar">
                                   <Icon name="menu" />
                                   <span>Dark</span>
                                   <span className="rui-dropdown-circle" />
                               </label>
                           </div>
                       </li>
                   </Dropdown.Menu>
               </Dropdown>
            </>
        );
    }

    render() {
        
        const {
            settings,
        } = this.props;

        const {
            mobileMenuShow,
        } = this.state;
      
        const customStyles = {
           control: (css, state) => {
               return {
                   ...css,
                   borderColor: state.isFocused ? 'rgba(114, 94, 195, 0.6)' : '#eaecf0',
                   '&:hover': {
                       borderColor: state.isFocused ? 'rgba(114, 94, 195, 0.6)' : '#eaecf0',
                   },
                   boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(114, 94, 195, 0.2)' : '',
               };
           },
           option: (css, state) => {
               let bgc = '';

               if (state.isSelected) {
                   bgc = '#725ec3';
               } else if (state.isFocused) {
                   bgc = 'rgba(114, 94, 195, 0.2)';
               }

               return {
                   ...css,
                   backgroundColor: bgc,
               };
           },
           multiValueLabel: (css) => {
               return {
                   ...css,
                   color: '#545b61',
                   backgroundColor: '#eeeeef',
               };
           },
       }


       var category_array = this.state.category_array.map(item => {
           return {
               value: item.id,
               label: item.category_name,
           }
       })

       var sub_category_array = this.state.sub_category_array.map(item => {
           return {
               value: item.id,
               label: item.sub_category_name,
           }
       })

       const type_array = [
           { value: '1', label: 'credit' },
           { value: '2', label: 'debit' },
       ];


        return (
            <>
                { /* Nav Menu */}
                <nav className={
                    classnames(
                        'rui-navbar rui-navbar-top',
                        settings.nav_dark ? 'rui-navbar-dark' : '',
                        settings.nav_sticky ? 'rui-navbar-sticky' : '',
                        settings.nav_fixed ? 'rui-navbar-fixed' : '',
                        settings.nav_expand ? `rui-navbar-expand-${settings.nav_expand}` : '',
                    )
                }
                >

                    <div className="rui-navbar-brand">
                        {settings.nav_logo_path ? (
                            <Link to={settings.nav_logo_url} className="rui-navbar-logo">
                                <img src={settings.night_mode || settings.nav_dark ? settings.nav_logo_path : settings.nav_logo_path} alt="" width={settings.nav_logo_width} />
                            </Link>
                        ) : ''}

                        <button className="yay-toggle rui-yaybar-toggle" type="button">
                            <span />
                        </button>
                    </div>
                    <div className={`container${settings.nav_container_fluid ? '-fluid' : ''}`}>
                        <div className="rui-navbar-content pooop ">
                        <h1 className="nav_head" style={{marginBottom:"0px"}}>{this.state.nav_heading}</h1>

                        {/* <div className="col-lg-2 col-md-2 col-xs-12" style={{display : this.state.nav_heading =="Home Ledger Management" ? "block" :"none"}}>
                            <Select
                            value = {this.state.category_id}
                            onChange={(e) => {
                                console.log(e, "Val.....")
                                this.setState({
                                    category_id: e
                                });
                                this.fetch_sub_category(e.value)
                                  this.fetch_home_ledger(e,this.state.sub_category_id,this.state.type,this.state.date_range);
                            }}

                            placeholder="Select category"
                            options={ category_array }
                            styles={ customStyles }
                                  />

                        </div>
                        <div className="col-lg-2 col-md-2 col-xs-12" style={{whiteSpace:"nowrap", display : this.state.nav_heading =="Home Ledger Management" ? "block" :"none"}}>
                            <Select
                                            value = {this.state.sub_category_id}
                                            onChange={(e) => {
                                                console.log(e, "Val.....")
                                                this.setState({
                                                    sub_category_id: e
                                                });
                                                this.fetch_home_ledger(this.state.category_id,e,this.state.type,this.state.date_range);
                                            }}
                                            placeholder="Select sub category"
                                            options={ sub_category_array }
                                            styles={ customStyles }
                                  />

                        </div>
                        <div className="col-lg-2 col-md-2 col-xs-12 hide_row" style={{display : this.state.nav_heading =="Home Ledger Management" ? "block" :"none"}}>
                            <Select
                                            value = {this.state.type}
                                            onChange={(e) => {
                                                console.log(e, "Val.....")
                                                this.setState({
                                                    type: e
                                                });
                                                this.fetch_home_ledger(this.state.category_id,this.state.sub_category_id,e,this.state.date_range);
                                            }}
                                            placeholder="Select type"
                                            options={ type_array }
                                            styles={ customStyles }
                                  />

                        </div>
                        <div className="col-lg-2 col-md-2 col-xs-12 hide_row" style={{display : this.state.nav_heading =="Home Ledger Management" ? "block" :"none"}}>
                        <RangeDatePicker
                          name="daterange"
                          startDate={this.state.start_date}
                          endDate={this.state.end_date}
                          onChange={(start_date, end_date) => {
                            this.setState({
                              start_date: start_date,
                              end_date: end_date,
                              // [new Date(startDate).toISOString(), new Date(endDate).toISOString()]
                              date_range: [dateFormat(new Date(start_date), 'yyyy-mm-dd'), dateFormat(new Date(end_date), 'yyyy-mm-dd')]

                            })
                            this.fetch_home_ledger(this.state.category_id,this.state.sub_category_id,this.state.type,[dateFormat(new Date(start_date), 'yyyy-mm-dd'), dateFormat(new Date(end_date), 'yyyy-mm-dd')])
                          }
                          }
                          minDate={new Date(1900, 0, 1)}
                          maxDate={new Date(2100, 0, 1)}
                          dateFormat="DD-MM-YYYY  "
                          monthFormat="MM YYYY"
                          startDatePlaceholder="Start Date"
                          endDatePlaceholder="End Date"
                          disabled={false}
                          className="my-own-class-name nightclass a1"
                          startWeekDay="monday"
                        />

                        </div> */}


                        {/* <div className="col-lg-2 col-md-2 col-xs-12" style={{display : this.state.nav_heading =="Company Ledger Management" ? "block" :"none"}}>
                            <Select
                            value = {this.state.category_id}
                            onChange={(e) => {
                                console.log(e, "Val.....")
                                this.setState({
                                    category_id: e
                                });
                                this.fetch_sub_category(e.value)
                                  this.fetch_company_ledger(e,this.state.sub_category_id,this.state.type,this.state.date_range);
                            }}

                            placeholder="Select category"
                            options={ category_array }
                            styles={ customStyles }
                                  />

                        </div>
                        <div className="col-lg-2 col-md-2 col-xs-12" style={{whiteSpace:"nowrap", display : this.state.nav_heading =="Company Ledger Management" ? "block" :"none"}}>
                            <Select
                                            value = {this.state.sub_category_id}
                                            onChange={(e) => {
                                                console.log(e, "Val.....")
                                                this.setState({
                                                    sub_category_id: e
                                                });
                                                this.fetch_company_ledger(this.state.category_id,e,this.state.type,this.state.date_range);
                                            }}
                                            placeholder="Select sub category"
                                            options={ sub_category_array }
                                            styles={ customStyles }
                                  />

                        </div>
                        <div className="col-lg-2 col-md-2 col-xs-12 hide_row" style={{display : this.state.nav_heading =="Company Ledger Management" ? "block" :"none"}}>
                            <Select
                                            value = {this.state.type}
                                            onChange={(e) => {
                                                console.log(e, "Val.....")
                                                this.setState({
                                                    type: e
                                                });
                                                this.fetch_company_ledger(this.state.category_id,this.state.sub_category_id,e,this.state.date_range);
                                            }}
                                            placeholder="Select type"
                                            options={ type_array }
                                            styles={ customStyles }
                                  />

                        </div>
                        <div className="col-lg-2 col-md-2 col-xs-12 hide_row" style={{display : this.state.nav_heading =="Company Ledger Management" ? "block" :"none"}}>
                        <RangeDatePicker
                          name="daterange"
                          startDate={this.state.start_date}
                          endDate={this.state.end_date}
                          onChange={(start_date, end_date) => {
                            this.setState({
                              start_date: start_date,
                              end_date: end_date,
                              // [new Date(startDate).toISOString(), new Date(endDate).toISOString()]
                              date_range: [dateFormat(new Date(start_date), 'yyyy-mm-dd'), dateFormat(new Date(end_date), 'yyyy-mm-dd')]

                            })
                            this.fetch_company_ledger(this.state.category_id,this.state.sub_category_id,this.state.type,[dateFormat(new Date(start_date), 'yyyy-mm-dd'), dateFormat(new Date(end_date), 'yyyy-mm-dd')])
                          }
                          }
                          minDate={new Date(1900, 0, 1)}
                          maxDate={new Date(2100, 0, 1)}
                          dateFormat="DD-MM-YYYY  "
                          monthFormat="MM YYYY"
                          startDatePlaceholder="Start Date"
                          endDatePlaceholder="End Date"
                          disabled={false}
                          className="my-own-class-name nightclass a1"
                          startWeekDay="monday"
                        />

                        </div> */}

                            <ul className="nav">
                                {this.renderSubmenus(settings.navigation)}
                            </ul>
                            <ul className="nav rui-navbar-right">


                                {this.renderRightMenuItems()}
                            </ul>
                        </div>
                    </div>
                </nav>

                { /* Mobile Menu */}
                <nav className={
                    classnames(
                        'rui-navbar rui-navbar-mobile',
                        settings.nav_dark ? 'rui-navbar-dark' : '',
                        settings.nav_expand ? `rui-navbar-expand-${settings.nav_expand}` : '',
                        mobileMenuShow ? 'rui-navbar-show' : '',
                    )
                }
                >
                    <div className="rui-navbar-head">

                        {!mobileMenuShow ? (
                            <button className="rui-yaybar-toggle rui-yaybar-toggle-inverse yay-toggle" type="button" aria-label="Toggle side navigation">
                                <span />
                            </button>
                        ) : ''}
                        {settings.nav_logo_path ? (
                            <Link to={settings.nav_logo_url} className="rui-navbar-logo mr-auto">
                                <img  src={settings.night_mode || settings.nav_dark ? settings.nav_logo_white_path : settings.nav_logo_path} alt="" width={settings.nav_logo_width} />
                            </Link>
                        ) : ''}
                        {/* <Dropdown tag="div" direction="up" showTriangle>
                            <Dropdown.Toggle tag="a" href="#" className="dropdown-item rui-navbar-avatar">
                                <img src={ settings.users[ 0 ].img } alt="" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu tag="ul" className="nav dropdown-menu">
                                <li>
                                    <Link to="/profile" className="nav-link">
                                        <Icon name="plus-circle" />
                                        <span>Create new Post</span>
                                        <span className="rui-nav-circle" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/profile" className="nav-link">
                                        <Icon name="users" />
                                        <span>Manage Users</span>
                                        <span className="rui-nav-circle" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/profile" className="nav-link">
                                        <Icon name="check-circle" />
                                        <span>Check Updates</span>
                                        <span className="rui-nav-circle" />
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="#"
                                        className="nav-link"
                                        onClick={ this.logOut }
                                    >
                                        <Icon name="log-out" />
                                        <span>Log Out</span>
                                        <span className="rui-nav-circle" />
                                    </Link>
                                </li>
                            </Dropdown.Menu>
                        </Dropdown> */}
                        <li style={{  display: device_width < 1025 && device_width > 700 ? 'inline-flex' : "none" }}> <h1 style={{marginBottom:'0px',marginTop:'3px',marginRight:'10px'}}>{this.state.project_name}</h1>
                                 </li>
                        <button
                            className="navbar-toggler rui-navbar-toggle ml-5"
                            onClick={() => {
                                this.setState({
                                    mobileMenuShow: !mobileMenuShow,
                                });
                            }}
                        >
                            <span />
                        </button>

                    </div>
                    <Collapse isOpen={mobileMenuShow} className="navbar-collapse rui-navbar-collapse">
                        <div className="rui-navbar-content ">
                            <ul className="nav">
                                {this.renderSubmenus(settings.navigation, true)}

                                {this.renderRightMenuItems(true)}
                            </ul>
                        </div>
                    </Collapse>
                </nav>
                <div
                    className="rui-navbar-bg"
                    onClick={() => {
                        this.setState({
                            mobileMenuShow: !mobileMenuShow,
                        });
                    }}
                    onKeyUp={() => { }}
                    role="button"
                    tabIndex={0}
                />

            </>
        );
    }
}

export default connect(({ settings }) => (
    {
        settings,
    }
), {
    updateAuth: actionUpdateAuth,
    updateSettings: actionUpdateSettings,
})(PageNavbar);
